import WhyChooseUs from '@rsa-digital/evo-shared-components/components/WhyChooseUs';
import { SectionHeading } from '@rsa-digital/evo-shared-components/components/WhyChooseUs/styles';
import styled from 'styled-components';

export const StyledWhyChooseUs = styled(WhyChooseUs)`
  && {
    p.caveat-copy {
      font-size: 16px;
    }
  }

  ${SectionHeading} {
    display: none;
  }
`;
